<template>
    <div class="profile-view-container">
        <nav-bar sizetitle="md"></nav-bar>
        <div class="user-container">
            <div class="pv__user-container">
                <div class="pv__avatar-container">
                    <img :src="avatar" :alt="nombre">
                </div>
                <div class="pv__name">
                    <div>{{nombre}}</div>
                    <div>{{apellidos}}</div>
                </div>
            </div>
            <div class="pv__graph-container">
                <div class="pv__radar-graph">
                    <radar-graph-user/>
                </div>
            </div>
        </div>
        <div class="pv__user-options">
            <router-link to="/editar-datos" class="btn-edit">
                    Editar perfil
            </router-link>
            <button class="btn-logout" @click="logout()">Cerrar sesión</button>
        </div>
    </div>
</template>
<script>
import {db,auth} from '../firebase'
import NavBar from '../components/NavBar'
import RadarGraphUser from '../components/charts/RadarGraphUser'
export default {
    components:{
        NavBar,
        RadarGraphUser
    },
    data:()=>({
        nombre:'',
        apellidos:'',
        avatar:''
    }),
    methods:{
        getCurrentUser(){
            return new Promise((resolve,reject)=>{
                const unsubscribe = auth.onAuthStateChanged(
                user =>{
                    unsubscribe();
                    resolve(user);
                },
                ()=>{
                    reject();
                }
                );
            });
        },
        async getDataUser(){
            try{
                let user = await  this.getCurrentUser();
                let docRef = db.collection("usuarios").doc(user.uid);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        this.nombre = doc.data().nombres;
                        this.apellidos = doc.data().paterno+' '+doc.data().materno
                        this.avatar = doc.data().avatar
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }catch(err){
                console.log(err)
            }
        },
        logout(){
            auth.signOut().then(() => {
                // Sign-out successful.
                this.$router.replace('/login')
            }).catch((error) => {
                // An error happened.
                console.log(error)
            });
        }
    },
    mounted(){
        this.getDataUser()
    }
}
</script>
<style>
.profile-view-container{
    width: 100%;
    min-height: 100vh;
    padding-bottom:7em;
    background-color: #1F0275;
}
.pv__user-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:2em;
}
.pv__avatar-container{
    width: 6em;
    height: 6em;
    background-color: wheat;
    border-radius: 50%;
    border:solid 3px rgb(242, 56, 39);
    overflow: hidden;
}
.pv__avatar-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.pv__name{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;    
    min-height: 3em;
    transition: all 0.5s;
}
.pv__name div:nth-child(1){
    font-size: 1.5em;
    transition: all 0.5s;
}
.pv__graph-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pv__graph-title{
    color: #f88989;
    font-size: 2em;
    transition: all 0.5s;
}
.pv__radar-graph{
    width: 90%;
}
.pv__user-options{
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height:8em;
}
.btn-edit{
    color:white;
}
.btn-logout{
    background-color: tomato;
    color:white;
    border:0px;
    font-size: 1.2em;
    margin:0.4em 1em;
}
@media (min-width: 900px) and (orientation: landscape) {
    .user-container{
        display: flex;
        justify-content: space-around;
    }
    .pv__user-container{
        width: 40%;
    }
    .pv__graph-container{
        width: 50%;
        height: 80vh;
    }
    .pv__radar-graph{
        width: 60%;
        height: 80vh;
    }
    .pv__avatar-container{
        width: 20em;
        height: 20em;
    }
}
</style>