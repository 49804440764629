<template>
  <div class="small">
    <radar-chart :chartData="datacollection" :options="options"></radar-chart>
  </div>
</template>

<script>
  import RadarChart from './RadarChart.js'
  import {auth,db} from '../../firebase'
  export default {
    components: {
      RadarChart
    },
    data () {
      return {
        datacollection: null,
        loaded:false,
        users:{},
        /*options: {
                    responsive: true,
                    scale: {
                        ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 500,
                        stepSize: 100
                        },
                        pointLabels: {
                        fontSize: 12
                        }
                    },
                    legend: {
                        position: 'top'
                    }
                    },*/
        options:{
                    legend: {
                    position: 'top',
                    labels: {
                        fontColor: 'rgb(235, 141, 86)',
                    }
                    },
                    title: {
                    display: true,
                    //text: 'Chart.js Radar Chart',
                    fontColor: 'white'
                    },
                    scale: {
                    ticks: {
                        beginAtZero: true,
                        fontColor: 'transparent', // labels such as 10, 20, etc
                        showLabelBackdrop: false // hide square behind text
                    },
                    pointLabels: {
                        fontColor: 'white' // labels around the edge like 'Running'
                    },
                    gridLines: {
                        color: 'rgba(255, 255, 255, 0.2)'
                    },
                    angleLines: {
                        color: 'white' // lines radiating from the center
                    }
                    }
                },
        avgPercepcion:0,
        avgCalculo:0,
        avgRazonamiento:0,
        avgEspacio:0,
        avgMemoria:0,
        dataResume:[]
        }
    },
    computed:{
    },
    methods: {
      fillData(){
        this.datacollection = {
          labels:['Percepción','Cálculo','Razonamiento','Lenguaje','Memoria'],
          datasets:[
            {
              label:'PROMEDIO DE HABILIDAD',
              backgroundColor:'rgba(242, 56, 39,0.5)',
              borderColor:'rgba(242, 56, 39,1)',
              pointBackgroundColor:'#F23827',
              borderWidth:1,
              pointBorderColor:'red',
              data:this.dataResume
            },

          ]
        }
      },
      getCurrentUser(){
            return new Promise((resolve,reject)=>{
                const unsubscribe = auth.onAuthStateChanged(
                user =>{
                    unsubscribe();
                    resolve(user);
                },
                ()=>{
                    reject();
                }
                );
            });
        },
        async getAverageRazonamiento(){
            let user = await  this.getCurrentUser();
            let suma = 0;
            let cont = 0;
            db.collection("usuarios").doc(user.uid)
            .collection("historial").where("tipo","==","razonamiento")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.data().score);
                    suma+=doc.data().score;
                    cont++;
                });
                if(cont!=0){
                  this.dataResume.push(Math.round(suma/cont));
                }else{
                  this.dataResume.push(0);
                }
            });
        },
        async getAverageCalculo(){
            let user = await  this.getCurrentUser();
            let suma = 0;
            let cont = 0;
            db.collection("usuarios").doc(user.uid)
            .collection("historial").where("tipo","==","calculo")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.data().score);
                    suma+=doc.data().score;
                    cont++;
                });
                
                if(cont!=0){
                  this.dataResume.push(Math.round(suma/cont));
                }else{
                  this.dataResume.push(0);
                }
            });
        },
        async getAveragePercepcion(){
            let user = await  this.getCurrentUser();
            let suma = 0;
            let cont = 0;
            db.collection("usuarios").doc(user.uid)
            .collection("historial").where("tipo","==","percepcion")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.data().score);
                    suma+=doc.data().score;
                    cont++;
                });
                if(cont!=0){
                  this.dataResume.push(Math.round(suma/cont));
                }else{
                  this.dataResume.push(0);
                }
            });
        },
        async getAverageLenguaje(){
            let user = await  this.getCurrentUser();
            let suma = 0;
            let cont = 0;
            db.collection("usuarios").doc(user.uid)
            .collection("historial").where("tipo","==","lenguaje")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.data().score);
                    suma+=doc.data().score;
                    cont++;
                });
                if(cont!=0){
                  this.dataResume.push(Math.round(suma/cont));
                }else{
                  this.dataResume.push(0);
                }
            });
        },
        async getAverageMemoria(){
            let user = await  this.getCurrentUser();
            let suma = 0;
            let cont = 0;
            db.collection("usuarios").doc(user.uid)
            .collection("historial").where("tipo","==","memoria")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.data().score);
                    suma+=doc.data().score;
                    cont++;
                });
                if(cont!=0){
                  this.dataResume.push(Math.round(suma/cont));
                }else{
                  this.dataResume.push(0);
                }
                this.fillData();
            });
        },
        getDataPercepcion(){
         const dataPercepcion = [];
            const idStud = this.$route.params.id;
            var rStudents = db.collection("usuarios").doc(idStud)
                              .collection("historial").where("tipo", "==", "percepcion");

            rStudents.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                console.log(doc.id, ' => ', doc.data().score);
                let record = doc.data().score;
                // record.id = doc.id;
                dataPercepcion.push(record);
             });
             this.dataPercepcion = dataPercepcion;
             
            })
            this.chartRecords();
                // console.log(dataPercepcion)
      },


    },
    async mounted(){
      try{
        await this.getAveragePercepcion();
        await this.getAverageCalculo();
        await this.getAverageRazonamiento();
        await this.getAverageLenguaje();
        await this.getAverageMemoria();
      } catch(err)
      {
        console.log(err)
      }
      
    }
  }
</script>

<style>
  .small {
    max-width: 100%;
  }
</style>